<template>
  <component :is="itemData === undefined ? 'div' : 'b-card'">
    <edit-tab
      :item-data="itemData"
      class="mt-1"
    />
  </component>

</template>

<script>
import {
  BAlert, BCard, BLink, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import EditTab from './EditTab.vue'
import grupoStoreModule from '../grupoStoreModule'
import store from '@/store'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    EditTab,
  },
  setup() {
    const itemData = ref({})

    const ITEM_APP_STORE_MODULE_NAME = 'app-grupo-favoritos'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, grupoStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
    // })

    if (router.currentRoute.params.id !== 'novo') {
      store.dispatch('app-grupo-favoritos/show', { id: router.currentRoute.params.id })
        .then(response => {
          itemData.value = response.data

        })
        .catch(error => {
          if (error.response.status === 404) {
            itemData.value = undefined
          }
        })
    } else {
      itemData.value = {}
    }

    return {
      itemData,

    }
  },
}
</script>
